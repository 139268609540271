import { Configuration } from "../configuration";
import { DigistoreCheckoutUrlFunction } from "../public-interfaces";
import { ds24_debug_function } from "./common/ds24_debug";
import { digistoreAppendAffiliateToUrl } from "./digistoreAppendAffiliateToUrl";

/**
 * Returns a url to the Digistore24 order form of the given product.
 * To return the correct domain that you are using for this product, please call this function after {@link digistorePromocode } was called and embedded to the page.
 * You can use the `config.callback` parameter of {@link digistorePromocode} function. 
 */
export const digistoreCheckoutUrl: DigistoreCheckoutUrlFunction = (product_id: number, query?: string) => {
    // eslint-disable-next-line prefer-rest-params
    ds24_debug_function('digistoreCheckoutUrl', [product_id, query]);
    if (!Configuration.promo_code_loaded) {
        console.log('Digistore24: Please call digistoreCheckoutUrl AFTER digistorePromocode completed, so that it can use the correct custom domain in the url');
    }
    let url = window.location.protocol + '//' + Configuration.orderform_domain + '/product/' + product_id;
    if (query) {
        url += (query.charAt(0) === '?' ? '' : '?') + query;
    }
    return digistoreAppendAffiliateToUrl(url);
};