import { DigistoreSanitizeLocationFunction } from "../public-interfaces";
import { ds24_debug_function } from "./common/ds24_debug";

/**
 * Diese Funktion wird beim einbinden des Promopixel automatisch aufgerufen.
 * Sie entfernt alle bekannten "Digistore-URL" Parameter wie z.B. der Affiliate, Campaign-Key, LINK-IDs,
 * UTM-Daten, etc. nach dem diese an Digistore übermittelt wurden.
 * Beispiel: Wenn ich meine Verkaufsseite mit aff als Query-Parameter aufrufe und die digistorePromoPixel()
 * Funktion eingebaut habe, sollte der Parameter in der Adresszeile nicht mehr zu sehen sein.
 * Die Funktion kann sämtliche Query-Parameter der aktuellen Seite entfernen.
 * Seite: https://www.meine-verkaufseite.de/produckt.html?aff=myaffiliate&select=1
 * Der Aufurf von digistoreSanitizeLocation(['aff']) entfernt den Query-Parameter aff in der Adresszeile
 * so dass diese dann wie folgt aussieht: https://www.meine-verkaufseite.de/produckt.html?select=1
 * @param args_names_to_remove array parmaters that should be removed
 * @example digistoreSanitizeLocation(['aff', 'cid']);
 * @returns 
 */
export const digistoreSanitizeLocation: DigistoreSanitizeLocationFunction = function( 
    args_names_to_remove: (string|string[])[] 
): void
{
    // eslint-disable-next-line prefer-rest-params
    ds24_debug_function('digistoreSanitizeLocation', [args_names_to_remove]);
    if (typeof window.DIGISTORE_KEEP_AFFILIATEIN_LOCATION_HREF != 'undefined' && window.DIGISTORE_KEEP_AFFILIATEIN_LOCATION_HREF)
    {
        return;
    }

    var url = location.href;

    var pos_q = location.href.indexOf( '?' );
    var pos_a = location.href.indexOf( '#' );

    var have_querystring = pos_q >= 0;
    var have_anchor      = pos_a >= 0;

    if (!have_querystring && !have_anchor)
    {
        return;
    }

    var pos = have_querystring ? pos_q : pos_a;

    var hostname    = url.slice( 0, pos );

    var querystring = have_querystring
        ? (have_anchor
            ? url.slice( pos_q+1, pos_a )
            : url.slice( pos_q+1 ))
        : '';

    var anchor      = have_anchor
        ? url.slice( pos_a+1 )
        : '';

    if (have_querystring) {
        querystring = _digistoreSanitizeLocationPart( args_names_to_remove, querystring );
    }

    if (have_anchor) {
        anchor = _digistoreSanitizeLocationPart( args_names_to_remove, anchor );
    }


    var url = hostname;
    if (querystring) {
        url += '?' + querystring;
    }
    if (anchor) {
        url += '#' + anchor;
    }

    history.pushState("", document.title, url );
}

function _digistoreSanitizeLocationPart( args_names_to_remove: (string|string[])[], text: string ): string
{
    var parts = text.split( '&' );
    var result = '';

    for (let i in parts)
    {
        var part = parts[i];

        var pos = part.indexOf( '=' );

        var arg = pos >= 0
            ? part.slice( 0, pos )
            : part;

        var is_removed = false;

        for (let j in args_names_to_remove) {

            if (is_removed) break;

            var one_arg_to_remove = args_names_to_remove[j];

            var one_list_to_remove = typeof one_arg_to_remove == 'object'
                ? one_arg_to_remove
                : [one_arg_to_remove];

            for (let k in one_list_to_remove) {

                is_removed = one_list_to_remove[k] === arg;
                if (is_removed) {
                    break;
                }
            }
        }

        if (is_removed )
            continue;

        if (result) {
            result += '&';
        }

        result += part;
    }

    return result;
}
