import { LinkReplacerInterface } from "../browser/LinkReplacer";

export class BuyLinkDomainReplacer implements LinkReplacerInterface {

    private domain: string;

    constructor(baseUrl: string) {
        const hasProtocol = baseUrl.indexOf('://');
        if (hasProtocol >= 0) {
            this.domain = baseUrl.slice(hasProtocol + 3);
        } else {
            this.domain = baseUrl;
        }
    }

    isValidLink(a: HTMLAnchorElement): boolean {
        return a.host !== this.domain && !!a.pathname.match(/^\/product\/[0-9]+/);
    }

    replaceLink(a: HTMLAnchorElement): void {
        a.host = this.domain;
    }

    isValidUrl(): boolean {
        return false;
    }

    replaceUrl(url: string): string {
        return url;
    }
}
