
export async function request<T>(options: {
    method?: 'GET',
    url: string,
    dataType: 'json' | 'jsonp',
    jsonpCallbackFunctionParameter?: string,
    data?: any,
    success?: (responseData: T) => void,
}): Promise<T> {

        return new Promise((resolve, reject) => {

        

        if (options.dataType === 'jsonp') {
            requestJsonP<T>(options.url, options.jsonpCallbackFunctionParameter ?? 'jsonp_callback', (d) => {
                if (options.success) {
                    options.success(d);
                }
                resolve(d);
            });
            return;
        }

        /**
         * Hier wird ein Prefetch vermieden, da dieser im Backend nicht
         * implementiert ist!
         * Hinweise wie man diesen vermeidet: https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#preflighted_requests
         */

        const request = new XMLHttpRequest();
        request.open(!options.method ? 'GET' : options.method, options.url, true);
        
        if (options.dataType === 'json') {
            request.setRequestHeader('Accept', 'application/json');
        }

        request.onload = () => {
            const responseData = JSON.parse(request.responseText);
            if (options.success) {
                options.success(responseData);
            }
            resolve(responseData);
        };

        request.send(options.data ? JSON.stringify(options.data) : null);
    });
}

let __request_jsonp_unique_id_count = 0;

export function requestJsonP<T>(url: string, callbackFunctionParameter: string, callback: (data: T) => void) {
    
    const jsonpCallbackName = '__wrap_jsonp_response_' + __request_jsonp_unique_id_count++;
    (window as any)[jsonpCallbackName] = function(responseData: any) {
        callback(responseData);
    };
    const scriptUrl = url + (url.indexOf('?') >= 0 ? '&' : '?') + callbackFunctionParameter + '=' + encodeURIComponent(jsonpCallbackName);
    const scriptNode = document.createElement('script');

    const cleanup = function() {
        document.head.removeChild(scriptNode);
        delete (window as any)[jsonpCallbackName];
    };

    scriptNode.setAttribute('src', scriptUrl);
    scriptNode.addEventListener('load', cleanup);
    scriptNode.addEventListener('error', cleanup);
    document.head.appendChild(scriptNode);
}
