export function flushQueue() {
    if (!window._ds24) {
        window._ds24 = { q:[], e: flushQueue };
    }
    const windowAny = window as any;
    while(window._ds24.q.length > 0) {
        const [functionName, ...args] = window._ds24.q.shift() as [string, ...any[]];
        if (windowAny[functionName]) {
            windowAny[functionName].apply(window, args);
        } else {
            console.warn('Coud not call "' + functionName + '" from ds24 queue');
        }
    }
    window._ds24.e = flushQueue;
}
