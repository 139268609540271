import { DigistorePromoPixelFunction } from "../public-interfaces";
import { digistorePromoPixel } from "./digistorePromoPixel";

export const digibankPromoPixel: DigistorePromoPixelFunction = _digibankPromoPixel;

function _digibankPromoPixel( product_id?: number, affiliate_key?: string, 
    campaignkey_key?: string, email_key?: string ): void;
function _digibankPromoPixel( config: {
    product_id: number,
    email_key?: string,
    adjust_all_urls?: boolean,
    adjust_domain?: boolean,
}): void;
function _digibankPromoPixel( config?: any,  affiliate_key?: string, 
    campaignkey_key?: string, email_key?: string): void
{
    digistorePromoPixel( config,  affiliate_key, 
        campaignkey_key, email_key );
};
