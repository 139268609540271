import { LinkReplacerInterface } from "../browser/LinkReplacer";
import { urlSearchParamsDecode } from "../browser/url-search-params-decode";
import { DEFAULT_PROMOPIXEL_DOMAINS } from '../common/ds24_root_url';
import { ds24_url_parameter_append, ds24_url_parameter_build } from "../common/ds24_url_parameter_append";
import { PromoParameter } from "./model";

export class AffiliateTrackingLinkAppender implements LinkReplacerInterface {

    private appendSearchString: string;

    constructor(
        private trackAllDomain: boolean, 
        private parameterToAppend: (keyof PromoParameter)[],
        private parameterValues: PromoParameter) {
        

        this.appendSearchString = this.parameterToAppend
            .filter(key => !!(this.parameterValues[key]))
            .map(param => (param + '=' + this.parameterValues[param])).join('&');
    }

    isValidLink(a: HTMLAnchorElement): boolean {
        return !!this.appendSearchString && (
            this.trackAllDomain 
            || DEFAULT_PROMOPIXEL_DOMAINS.indexOf('http://' + a.host) >= 0
            || DEFAULT_PROMOPIXEL_DOMAINS.indexOf('https://' + a.host) >= 0
            );
    }

    replaceLink(a: HTMLAnchorElement): void {
        if (a.search) {
            a.search = '?' + 
                ds24_url_parameter_build(a.search, this.parameterValues as any) +
                '&' + this.appendSearchString;
        } else {
            a.search = '?' + this.appendSearchString;
        }
    }

    isValidUrl(url: string): boolean {
        return !!this.appendSearchString && !!DEFAULT_PROMOPIXEL_DOMAINS.find((domain) => url.startsWith(domain));
    }

    replaceUrl(url: string): string {
        return ds24_url_parameter_append(url, this.parameterToAppend, this.parameterValues as any);
    }

}