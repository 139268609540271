import { PARAMETER_TO_APPEND } from "../configuration";
import { DigistoreAppendAffiliateToUrlFunction } from "../public-interfaces";
import { ds24_debug_function } from "./common/ds24_debug";
import { ds24_url_parameter_append } from "./common/ds24_url_parameter_append";
import { ds24_promopixel_read_parameter } from "./promopixel/ds24_promopixel";

/**
 * Returns given url with appended tracking parameters, if some exists.
 */
export const digistoreAppendAffiliateToUrl: DigistoreAppendAffiliateToUrlFunction = (url: string) => {
    // eslint-disable-next-line prefer-rest-params
    ds24_debug_function('digistoreAppendAffiliateToUrl', [url]);
    const parameter = ds24_promopixel_read_parameter();

    return ds24_url_parameter_append(url, PARAMETER_TO_APPEND, parameter as any);
};
