import { ds24_checkout_url } from './ds24_checkout_url';
import { ds24UpsellModal } from './ds24_upsell_modal';
import { useFirstRadio, initializeDs24UpsellButtons } from './ds24_upsell_box';

let ds24_upsell_added_not_buy_link: boolean;

export function ds24_upsell_loader() {
  ds24_set_upsell_session_id(window.DS24_UPSELL_SESSION_ID as string);
}

export function ds24_get_upsell_session_id(seperator: string): string | false {
  const session_id_key = 'digistore_upsell_session_id';
  let tokens = location.href.split(seperator);
  const querystring = tokens[1];
  const have_querystring = typeof querystring != 'undefined';

  if (!have_querystring) {
    return false;
  }

  const args = querystring.split('&');
  for (let i = 0; i < args.length; i++) {
    const arg = args[i];

    tokens = arg.split('=');

    const key = tokens[0];
    const value = tokens[1];

    if (key == session_id_key && value) {
      const parts = value.split('#');

      /* istanbul ignore next: Kann nie erreicht werden, da parts.length immer > 0 ist! */
      return parts ? parts[0] : value;
    }
  }

  return false;
}

export function ds24_set_upsell_session_id(session_id: string) {
  /**
   * Initialize first upsell radio option
   */
  useFirstRadio();

  /**
   * new Feature initialize upsell buttons
   */
  initializeDs24UpsellButtons(session_id);

  let have_no_url = 0;
  let have_yes_url = 0;

  document.querySelectorAll('a').forEach((linkElement: HTMLAnchorElement) => {
    const { hasNoLink, hasYesLink } = insertUpsellSessionIdToAnchor(session_id, linkElement);
    if (hasNoLink) {
      have_no_url++;
    }
    if (hasYesLink) {
      have_yes_url++;
    }
  });

  if (
    !have_no_url &&
    have_yes_url &&
    typeof ds24_upsell_added_not_buy_link == 'undefined'
  ) {
    ds24_upsell_added_not_buy_link = true;
    // fallback for IE
    const MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER || 9007199254740991;
    const url = ds24_checkout_url() + '/answer/' + session_id + '/upsell_no_button/';

    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', url);
    iframe.setAttribute(
      'style',
      'width: 500px; height: 150px; position: fixed; z-index: ' +
        MAX_SAFE_INTEGER +
        '; ' +
        'bottom: 0px; left: calc( 50% - 250px ); background-color: transparent; border: none; shadow: none;'
    );
    document.body.appendChild(iframe);
  }
}


export function insertUpsellSessionIdToAnchor(session_id: string, linkElement: HTMLAnchorElement) {
  let hasNoLink = false;
  let hasYesLink = false;
  const href = linkElement.getAttribute('href');

  if (!href) {
    return {
      hasNoLink,
      hasYesLink,
    };
  }

  let new_url: false | string = false;

  /**
   * example: href_lower = "https://www.digistore24.com/answer/yes?my_parameter=test&qty=10"
   * match[0] is the href itself
   * match[1] is the url without the choice ("https://www.digistore24.com/answer/")
   * match[2] is the answer choice itself ("yes" or "no")
   * match[3] is the "rest" (e.g. "?my_parameter=test&qty=10")
   */
  const match = href.match(/(.*\/answer\/)(yes|no)(\/*|\/*\?.*)$/i);

  if (match) {
    const choice = match[2].toLowerCase();

    if (choice === 'no') {
      hasNoLink = true;
    }

    if (choice === 'yes') {
      hasYesLink = true;
    }

    new_url = match[1] + session_id + '/' + choice + match[3];
  } else {
    if (href.indexOf('digistore_upsell_session_id=' + session_id) < 0) {
      if (href.indexOf('digistore_upsell_session_id') > 0) {
        new_url = href.replace(
          /digistore_upsell_session_id/g,
          'digistore_upsell_session_id=' + session_id
        );
      }
    }
  }

  if (new_url) {
    linkElement.setAttribute('href', new_url);
  }

  // !! must be executed after href is modified with session id to also catch upsell-urls generated via WP-Plugin. !!
  if (linkElement.getAttribute('href')?.match(/\/answer\/.*\/yes.*$/i)) {

    /**
     * Fallback for old upsell links (https://digistore.atlassian.net/browse/DS-11686)
     * @param $event
     */
    linkElement.onclick = function ($event) {
      const upsellAHref = (<HTMLAnchorElement>$event.currentTarget).href;
      ds24UpsellModal(upsellAHref);
      $event.preventDefault();
    };
  }

  return {
    hasNoLink,
    hasYesLink,
  };
}
