const style = `
  <style>

    *  {
      box-sizing: border-box;
    }

     iframe {
      border: 0;
      min-width: 300px;
     }

    .ds-modal--backdrop {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, .5);
      z-index: 9998;
    }

    .ds-modal--header {
      display: flex;
      align-items: center;
      padding: 12px 24px;
      background: #fff;
      position: relative;
    }

    .center-pos {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0 auto;
      z-index: 9998;
    }

    .ds-modal {
      background: #fff;
      border-radius: 6px;
      /*padding: 12px 24px;*/
      min-width: 300px;
      overflow: hidden;
    }

    .ds-modal--title {
      font-size: 150%;
      width: 100%;
    }

    .ds-modal--dismiss {
      cursor: pointer;
    }

    .ds-modal--content {
      min-height: 50px;
    }

    .ds-modal--buttons {
    }

    .ds-show-only-closing .ds-modal--dismiss {
      position: relative;
      right: -42px;
      top: 10px;
    }

    .ds-show-only-closing.ds-modal {
      overflow: inherit;
      border-radius: unset;
      background:transparent;
    }

    .ds-show-only-closing .ds-modal--header {
      background:transparent;
    }

    .ds-show-only-closing .ds-modal--content {
      border-radius: 6px;
      overflow: hidden;
    }

    .ds-loading {
      display: inline-block;
      width: 50px;
      height: 50px;
      border: 3px solid rgba(255,255,255,.3);
      border-radius: 50%;
      border-top-color: #fff;
      animation: spin 1s ease-in-out infinite;
      -webkit-animation: spin 1s ease-in-out infinite;
    }

    @keyframes spin {
      to { -webkit-transform: rotate(360deg); }
    }
    @-webkit-keyframes spin {
      to { -webkit-transform: rotate(360deg); }
    }

  </style>
  `;

export class DS24Modal {
  _container: HTMLDivElement;

  useShadowDom = true;

  constructor(
    // istanbul ignore next
    private randFunctionKey: string = 'DsUpsellModal-' + Math.random()
  ) {
    const body = document.body;
    this._container = document.createElement('div');
    this._container.setAttribute('data-e2e', 'ds24-shadow-modal');
    if (this.useShadowDom) {
      this._container.attachShadow({
        mode: 'open',
      });
    }
    body.appendChild(this._container);

    // @ts-ignore
    window[this.randFunctionKey] = this;
  }

  /**
   * Get Root container of the Banner
   */
  getContainer(): HTMLDivElement {
    return (
      this.useShadowDom ? this._container.shadowRoot : this._container
    ) as HTMLDivElement;
  }

  /**
   * Remove banner
   */
  close = () => {
    this.getContainer().innerHTML = '';
  };

  /**
   * Create a Banner in the container
   */
  openOverlay = (content: string) => {
    this.getContainer().innerHTML = `
      ${style}
      <div class="ds-modal--backdrop" onclick="window.${this.randFunctionKey}.close()"></div>
      <div class="center-pos">
        ${content}
      </div>
      `;
  };

  loading() {
    this.openOverlay(`<div class="ds-loading"></div>`);
  }

  modal(title: string, content: string, buttons = '', showOnlyClosing = false) {
    this.openOverlay(
      `
               <div class="ds-modal ${showOnlyClosing ? 'ds-show-only-closing' : ''}">
                  <div class="ds-modal--header">
                     <div class="ds-modal--title">${title}</div>
                     <svg  class="ds-modal--dismiss" onClick="window.${this.randFunctionKey}.close()" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 1.28182L16.7091 0L9 7.71818L1.28182 0L0 1.28182L7.71818 9L0 16.7091L1.28182 18L9 10.2818L16.7091 18L18 16.7091L10.2818 9L18 1.28182Z" fill="${showOnlyClosing ? '#FFFFFF' : '#758B9C'}"/>
                     </svg>
                  </div>
                  <div class="ds-modal--content">${content}</div>
                  <div class="ds-modal--buttons">
                    ${buttons}
                  </div>
                </div>
`
    );
  }
}
