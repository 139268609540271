
export interface LinkReplacerInterface {
    isValidLink(a: HTMLAnchorElement): boolean;
    replaceLink(a: HTMLAnchorElement): void;
    isValidUrl(url: string): boolean;
    replaceUrl(url: string): string;
}

export class LinkReplacerEngine {

    private observer: MutationObserver | undefined;
    private linkReplacerList: LinkReplacerInterface[] = [];

    private replaceLink(a: HTMLAnchorElement) {
        this.linkReplacerList.forEach(replacer => {
            if (replacer.isValidLink(a)) {
                replacer.replaceLink(a)
            }
        });
    }

    private replaceIframeUrl(iframe: HTMLIFrameElement) {
        return this.linkReplacerList.forEach((replacer) => {
            if (replacer.isValidUrl(iframe.src)) {
                iframe.src = replacer.replaceUrl(iframe.src);
            }
        });
    }

    add(replacer: LinkReplacerInterface) {
        this.linkReplacerList.push(replacer);
    }
    
    run() {
        document.querySelectorAll('a').forEach((a: HTMLAnchorElement) => this.replaceLink(a));
        document.querySelectorAll('iframe').forEach((frame: HTMLIFrameElement) => this.replaceIframeUrl(frame));
        if (!window.MutationObserver) {
            return;
        }
        /*
        this.observer = new MutationObserver((list) => {
            list.forEach(item => {
                item.addedNodes.forEach((node) => {
                    if (node.nodeType !== Node.ELEMENT_NODE) {
                        return;
                    }
                    const anchorList: HTMLCollectionOf<HTMLAnchorElement> = (node as HTMLElement).getElementsByTagName('a');
                    for (let i = 0; i < anchorList.length; i++) {
                        if (!anchorList.item(i)) {
                            return;
                        }
                        this.replaceLink(anchorList.item(i) as HTMLAnchorElement);
                    }
                });
            });
        });

        this.observer.observe(document.body, {subtree: true, childList: true});
        */
    }

    stop() {
        this.observer?.disconnect();
    }


}