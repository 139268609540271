

export function urlSearchParamsDecode(encodedString: string): {[key: string]: string} {

    encodedString = ['#', '?'].indexOf(encodedString[0]) >= 0 ? encodedString.slice(1) : encodedString;
    
    const args = encodedString.split('&');

    return args.reduce((params: {[key: string]: string}, item) => {
        const [name, value] = item.split('=');
        params[name] = value;
        return params;
    }, {});
}