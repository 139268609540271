import './lib/initial-variables';

/**
 * Legacy Public Functions
 */
const publishObj = window as any;
import {
  ds24_set_cookie,
  ds24_get_cookie,
} from './lib/functions/common/ds24_cookie';

publishObj.ds24_set_cookie = ds24_set_cookie;
publishObj.ds24_get_cookie = ds24_get_cookie;
import { ds24_parse_settings } from './lib/functions/common/ds24_parse_settings';

publishObj.ds24_parse_settings = ds24_parse_settings;
import { ds24_retrieve } from './lib/functions/common/ds24_retrieve';

publishObj.ds24_retrieve = ds24_retrieve;
import {
  digistoreAddCustomDomain,
  ds24_root_url,
  ds24_set_root_url,
} from './lib/functions/common/ds24_root_url';

publishObj.digistoreAddCustomDomain = digistoreAddCustomDomain;
publishObj.ds24_root_url = ds24_root_url;
publishObj.ds24_set_root_url = ds24_set_root_url;
import { ds24_unique_id } from './lib/functions/common/ds24_unique_id';

publishObj.ds24_unique_id = ds24_unique_id;

import { digibankPromoPixel } from './lib/functions/digibankPromoPixel';

publishObj.digibankPromoPixel = digibankPromoPixel;
import { digibankUpsell } from './lib/functions/digibankUpsell';

publishObj.digibankUpsell = digibankUpsell;

import {
  digistoreSetEmailParamName,
  digistoreKeepAffiliateInLocationHref,
  digistoreSetAffiliate,
  digistoreSetEmail,
} from './lib/functions/variables-setter';

import { ds24UpsellModal } from './lib/functions/common/ds24_upsell_modal';

publishObj.digistoreSetEmailParamName = digistoreSetEmailParamName;
publishObj.digistoreKeepAffiliateInLocationHref =
  digistoreKeepAffiliateInLocationHref;
publishObj.digistoreSetAffiliate = digistoreSetAffiliate;
publishObj.digistoreSetEmail = digistoreSetEmail;
publishObj.createUpsellModal = ds24UpsellModal;
publishObj.digistoreCheckoutUrl = digistoreCheckoutUrl;

/**
 * Common Public Functions
 */
import { digistorePromoPixel } from './lib/functions/digistorePromoPixel';
import { digistoreAppendAffiliateToBuyUrls } from './lib/functions/digistoreAppendAffiliateToBuyUrls';
import { digistoreCart } from './lib/functions/digistoreCart';
import { digistoreSanitizeLocation } from './lib/functions/digistoreSanitizeLocation';
import { digistoreUpsell } from './lib/functions/digistoreUpsell';
import { digistoreSetUpsellChoice } from './lib/functions/common/ds24_upsell_box';
import { digistoreAppendAffiliateToUrl } from './lib/functions/digistoreAppendAffiliateToUrl';
import { digistoreCheckoutUrl } from './lib/functions/digistoreCheckoutUrl';
import { initialize } from './lib/init';


const brandFunction: { [key: string]: (...args: any[]) => any } = {
  digistorePromoPixel,
  digistoreAppendAffiliateToBuyUrls,
  digistoreSanitizeLocation,
  digistoreCart,
  digistoreUpsell,
  digistoreSetUpsellChoice,
  digistorePromocode: digibankPromoPixel,
  digitalElitePromocode: digibankPromoPixel,
  digistoreAppendAffiliateToUrl,
  digistoreCheckoutUrl,
};
Object.keys(brandFunction).forEach((originFunctionName) => {
  const brandedFunctionName = originFunctionName.replace(
    /^digistore/,
    __BRAND__
  );
  (window as any)[originFunctionName] = brandFunction[originFunctionName];
  (window as any)[brandedFunctionName] = brandFunction[originFunctionName];
});

initialize();
