import { urlSearchParamsDecode } from "../browser/url-search-params-decode";

export function ds24_url_parameter_build(query: string, excludingParameterObj: {[key: string]: string}): string {
    const existingParameter = urlSearchParamsDecode(query);
    return Object.keys(existingParameter)
            .filter(key => !(excludingParameterObj[key]))
            .map(param => (param + '=' + existingParameter[param])).join('&');


}

export function ds24_url_parameter_append(url: string, allowedParameterToAppend: string[], parameterValues: {[key: string]: string}): string {
    const existingParameterSplitPos = url.indexOf('?');
    const appendStr = allowedParameterToAppend
        .filter(key => !!(parameterValues[key]))
        .map(param => (param + '=' + parameterValues[param])).join('&');
    if (!appendStr) {
        return url;
    }
    if (existingParameterSplitPos >= 0) {
        return url = url.slice(0, existingParameterSplitPos + 1) + 
        ds24_url_parameter_build(url.slice(existingParameterSplitPos), parameterValues) +
            '&' + appendStr;

    }
    return url + '?' + appendStr;
}