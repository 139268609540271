import { Configuration } from "../configuration";
import { DigistorePromoPixelFunction } from "../public-interfaces";
import { documentReady } from "./browser/documentReady";
import { ds24_debug_function } from "./common/ds24_debug";
import { ds24_promopixel_loader } from "./promopixel/ds24_promopixel";

/**
 * Enables tracking.
 * This function looks for all links to Digistore24 order forms and adds the trackable parameters.
 * @example digistorePromoPixel({ product_id: '22', adjust_domain: true });
 * @param config.product_id Digistore24 product id
 * @param config.email_key Parmaeter name of email tracking parameter
 * @param config.affiliate_key Parameter name of affiliate tracking parameter
 * @param config.campaignkey_key Parameter name of campaign key tracking parameter
 * @param config.adjust_all_urls Set to true to append tracking parameters to each link of the page, e.g. to allow tracking of multi step sales pages.
 * @param config.adjust_domain Set to true to change all links that goes to Digistore24 order forms with the order form domain that was setup inside Digistore24.
 * @param config.callback Callback function that is called after promo code was embedded.
 */
export const digistorePromoPixel: DigistorePromoPixelFunction = _digistorePromoPixel;

function _digistorePromoPixel( product_id?: number, affiliate_key?: string, 
    campaignkey_key?: string, email_key?: string ): void;
function _digistorePromoPixel( config: {
    product_id: number,
    email_key?: string,
    affiliate_key?: string,
    campaignkey_key?: string,
    adjust_all_urls?: boolean,
    adjust_domain?: boolean,
    callback?: (affiliate: string, campaignkey: string) => void,
}): void;
function _digistorePromoPixel( config?: any,  affiliate_key?: string, 
    campaignkey_key?: string, email_key?: string): void
{
    // eslint-disable-next-line prefer-rest-params
    ds24_debug_function('digistorePromoPixel', arguments);
    let product_id = config;
    if (typeof(config) === 'object') {
        product_id = config.product_id;
        email_key = config.email_key;
        campaignkey_key = config.campaignkey_key;
        affiliate_key = config.affiliate_key;
        Configuration.adjust_all_urls = config.adjust_all_urls === true;
        Configuration.adjust_domain = config.adjust_domain === true;
    }

    let error_details = '';

    if (typeof affiliate_key != 'undefined') {
        Configuration.affiliate_key = affiliate_key;
    }

    if (typeof campaignkey_key != 'undefined') {
        Configuration.campaignkey_key = campaignkey_key;
    }

    if (typeof email_key != 'undefined') {
        Configuration.email_key = email_key;
    }

    switch (typeof product_id)
    {
        case 'undefined':
            error_details = "DigiStore24 product id missing.";
            break;

        case 'number':
            if (product_id<=0) {
                error_details = "DigiStore24 product id " + product_id + " out of range.";
                product_id = undefined;
            }
            break;

        case 'string':
            error_details = "Replace " + product_id + " by our DigiStore24 product id.";
            product_id = parseInt( product_id );
            if (product_id<=0 || isNaN(product_id)) {
                product_id = undefined;
            }
            break;

        default:
            error_details = "Invalid DigiStore24 product id.";
            product_id = undefined;
    }

    if (!product_id)
    {
        alert( "ERROR: " + error_details + "\n\nCheck your html code.\n\nMake sure it contains text like: digistorePromoPixel( 12345 )\n\nReplace 12345 by your product id." );
        return;
    }

    window.DIGISTORE_PRODUCT_ID = product_id;

    documentReady(async () => {
        await ds24_promopixel_loader();
        if (typeof(config.callback) === 'function') {
            config.callback(Configuration.promo_parameter.aff, Configuration.promo_parameter.cam);
        }
    });
}
