import { Configuration } from "../configuration";
import { DigistoreKeepAffiliateInLocationHrefFunction, DigistoreSetAffiliateFunction, DigistoreSetEmailFunction, DigistoreSetEmailParamNameFunction } from "../public-interfaces";
import { ds24_debug_function } from "./common/ds24_debug";

/**
 * Changes the parameter name that is used to read the email address.
 * Default is `email`. 
 * You need to call this function before {@link digistorePromocode } was called.
 * @example digistoreSetEmailParamName('mail')
 */
export const digistoreSetEmailParamName: DigistoreSetEmailParamNameFunction = function (email_key: string ): void
{
    // eslint-disable-next-line prefer-rest-params
    ds24_debug_function('digistoreSetEmailParamName', arguments);
    Configuration.email_key = email_key;
};

/**
 * Set email.
 * You need to call this function before {@link digistorePromocode } was called.
 */
export const digistoreSetEmail: DigistoreSetEmailFunction = function ( email_address: string ): void
{
    // eslint-disable-next-line prefer-rest-params
    ds24_debug_function('digistoreSetEmail', arguments);
    Configuration.promo_parameter.email = email_address;
};

/**
 * Set affiliate and a campaignkey.
 * You need to call this function before {@link digistorePromocode } was called.
 * @param affiliate user name of Digistore24
 * @param campaignkey could by any string
 */
export const digistoreSetAffiliate: DigistoreSetAffiliateFunction = function( 
    affiliate: string, campaignkey: string ): void
{
    // eslint-disable-next-line prefer-rest-params
    ds24_debug_function('digistoreSetAffiliate', arguments);
    Configuration.promo_parameter.aff = affiliate;
    Configuration.promo_parameter.cam = campaignkey;
};

/**
 * Prevent default behaviour to clean url bar from tracking parameters.
 * This has only effect if you call it before {@link digistorePromocode } was called.
 */
export const digistoreKeepAffiliateInLocationHref: DigistoreKeepAffiliateInLocationHrefFunction = function(): void
{
    // eslint-disable-next-line prefer-rest-params
    ds24_debug_function('digistoreKeepAffiliateInLocationHref', arguments);
    window.DIGISTORE_KEEP_AFFILIATEIN_LOCATION_HREF=true;
}
