
import { DigistoreCartFunction } from "../public-interfaces";
import { documentReady } from "./browser/documentReady";
import { ds24_debug_function } from "./common/ds24_debug";
import { ds24_parse_settings } from "./common/ds24_parse_settings";


let digistoreCart_initialized: boolean;

export function _TEST_reset_digistoreCart_initialized() {
    digistoreCart_initialized = undefined as any;
}

/**
 * Add shopping cart elements to the page and turns <code>/add/&lt;product-id&gt;</code> urls to shopping add buttons
 * @example digistoreCart('root_url=https://www.digistore24.com theme=clasic no_return_button=false');
 */
export const digistoreCart: DigistoreCartFunction = function (settings_string?: string ): void
{
    // eslint-disable-next-line prefer-rest-params
    ds24_debug_function('digistoreCart', arguments);
    if (typeof digistoreCart_initialized != 'undefined')
    {
        console.log( 'Digistore24: ignored second call of digistoreCart()' );
        return;
    }
    digistoreCart_initialized = true;

    window.DS24_NETTO            = false;
    window.DS24_CURRENCY_SYMBOL  = false;
    window.DS24_CURRENCY         = '';
    window.DS24_ORDERFORM_ID     = 0;
    window.DS24_RETURN_URL       = '';
    window.DS24_THANKYOU_URL     = '';
    window.DS24_CHECKOUT_URL     = '';
    window.DS24_NO_RETURN_BUTTON = false;
    window.DS24_UPSELL           = 'any';
    window.DS24_LANGUAGE         = '';
    window.DS24_TRACKING_PARAM   = '';
    window.DS24_OF_AS_POPUP      = false;
    window.DS24_INFO_POSITION    = 'top';
    window.DS24_THEME            = false;
    window.DS24_INFO_STICKY      = false;
    window.DS24_CONTINUE_URL     = '';
    window.DS24_CONTINUE_LABEL   = '';
    window.DS24_QUANTITY_RO      = false;
    window.DS24_SHOW_CART_SUM    = false;
    window.DS24_SHOW_CART_ITEMS  = false;
    window.DS24_ON_CART_CHANGE   = false;
    window.DS24_ON_MESSAGE       = false;
    window.DS24_VOUCHER          = '';

    window.DS24_TOOLTIP_SHOWN_BY_CLICK = false;

    const settings_array = ds24_parse_settings( settings_string );

    for (let key in settings_array)
    {
        var val = settings_array[ key ];

        switch (key)
        {
            case 'orderform_as_popup':
                window.DS24_OF_AS_POPUP = true;
                break;

            case 'netto':
                window.DS24_NETTO = true;
                break;

            case 'currency_symbol':
                window.DS24_CURRENCY_SYMBOL = true;
                break;

            case 'currency':
                window.DS24_CURRENCY = val;
                break;

            case 'orderform_id':
                window.DS24_ORDERFORM_ID = val;
                break;

            case 'upsell':
                window.DS24_UPSELL = val;
                break;

            case 'root_url':
                window.DIGISTORE_ROOT_URL = val.replace(/\/$/, "");
                break;

            case 'return_url':
                window.DS24_RETURN_URL = val;
                break;

            case 'thankyou_url':
                window.DS24_THANKYOU_URL = val;
                break;

            case 'checkout_url':
                window.DS24_CHECKOUT_URL = val;
                break;

            case 'lang':
            case 'language':
                window.DS24_LANGUAGE = val;
                break;

            case 'tracking':
            case 'tracking_key':
            case 'tracking_param':
            case 'ds24tr':
                window.DS24_TRACKING_PARAM = val;
                break;

            case 'no_return_button':
                window.DS24_NO_RETURN_BUTTON = val === ''
                    ? 1
                    : val;
                break;

            case 'info_position':
                window.DS24_INFO_POSITION = val.replace( /\W/g, '' );
                break;

            case 'info_sticky':
                window.DS24_INFO_STICKY = true;
                break;

            case 'theme':
                window.DS24_THEME = val.replace( /\W/g, '' );
                if (window.DS24_THEME == 'clasic')
                {
                    window.DS24_THEME = false;
                }
                break;

            case 'continue_url':
                window.DS24_CONTINUE_URL = val;
                break;

            case 'continue_label':
                window.DS24_CONTINUE_LABEL = val;
                break;

            case 'quantity_readonly':
                window.DS24_QUANTITY_RO = true;
                break;

            case 'show_cart_items':
                window.DS24_SHOW_CART_ITEMS = val;
                break;

            case 'show_total_amount':
                window.DS24_SHOW_CART_SUM = val;
                break;

            case 'on_cart_change':
                window.DS24_ON_CART_CHANGE = val;
                break;

            case 'on_message':
                window.DS24_ON_MESSAGE = val;
                break;

            case 'voucher':
                window.DS24_VOUCHER = val;
                break;
        }

        if (!window.DS24_RETURN_URL) {
            window.DS24_RETURN_URL = location.href;
        }
    }

    if (window.DS24_INFO_STICKY) {
        window.DS24_TOOLTIP_SHOWN_BY_CLICK = true;
    }

    const ds24_load_cart_js = async () => {
        const cart = await import(/* webpackMode: "lazy", webpackChunkName: "cart" */ '../../cart');
        documentReady(() => {
            for(let publicFunctionName in cart) {
                (window as any)[publicFunctionName] = (cart as any)[publicFunctionName];
            }
            cart.ds24cart_loader();
        });
    };

    ds24_load_cart_js();

}