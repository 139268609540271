export function ds24_parse_settings( settings?: string ): {[key: string]: any}
{
    if (!settings) {
        return {};
    }

    const settings_parsed: any = {};

    pos = 0;

    const tokens = settings.split( ' ' );

    let have_quote: boolean|string = false;

    let key: string = '';
    let val;

    for (let i in tokens)
    {
        if (have_quote) {
            const is_quote_closed = tokens[i].substr( -1 ) == have_quote;

            if (is_quote_closed) {

                val += ' ' + tokens[i].substr( 0, tokens[i].length-1 );
                settings_parsed[ key as any ] = val;

                val = '';
                key = '';

                have_quote = false;
            }
            else
            {
                val += ' ' + tokens[i];
            }
            continue;
        }

        var pos = tokens[i].indexOf('=');
        if (pos>=0)
        {
            key = tokens[i].substring( 0, pos ).toLowerCase();
            val = tokens[i].substring( pos+1 );

            const first_char = val.substr( 0, 1 );
            const last_char = val.substr( -1 );

            var have_start_quote = first_char === '"' || first_char === "'";
            var have_end_quote   = have_start_quote && first_char === last_char;

            if (!have_start_quote)
            {
                val = val.replace( /\"/g, '' ).replace( /\'/g, '' );
            }
            else if (have_start_quote && have_end_quote)
            {
                val = val.substring( 1, val.length-1 );
            }
            else
            {
                val = val.substring( 1 );
                have_quote = first_char;
            }
        }
        else
        {
            key = tokens[i].toLowerCase();
            val = '';
        }

        if (!have_quote) {

            if (key) {
                settings_parsed[ key ] = val;
            }

            key = '';
            val = '';
        }
    }

    if (key && val)
    {
        settings_parsed[ key ] = val;
    }

    return settings_parsed;
}
