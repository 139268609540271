import { PromoParameter } from "./functions/promopixel/model";

const PROMO_PARAMETER_INIT_VALUE = {
    ds24tr: '',
    aff: '',
    cam: '',
    cid: '',
    sid1: '',
    sid2: '',
    sid3: '',
    sid4: '',
    sid5: '',
    a: '',
    c: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: '',
    email: '',
    testpay: '',
    testpay_team: '',
};


export function get_default_orderform_domain() {
    const baseUrl = __CHECKOUT_URL__;
    const hasProtocol = baseUrl.indexOf('://');
    if (hasProtocol >= 0) {
        return baseUrl.slice(hasProtocol + 3);
    }
    return baseUrl;
}

export class Configuration {

    public static adjust_domain = false;
    public static adjust_all_urls = false;

    public static checkout_url = __CHECKOUT_URL__;

    public static orderform_domain = get_default_orderform_domain();
    public static promo_code_loaded = false;

    public static promo_parameter: PromoParameter = PROMO_PARAMETER_INIT_VALUE;

    public static affiliate_key   = 'aff';
    public static campaignkey_key = 'cam';
    public static vendor_key      = 'ds24tr';

    public static link_id_sep     = '~'; // keep in sync with Promo_click_table::subid_seperator()

    public static link_id_keys     = [ 'cid', 'sid1', 'sid2', 'sid3', 'sid4', 'sid5' ]; // keep in sync with Promo_click_table::click_id_get_param_name() / subid_get_param_names()
    public static utm_keys         = [ 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content' ]; // keep in sync with Promo_click_table::utm_param_names()
    public static click_amt_keys   = [ 'a', 'c' ];
    public static email_key        = 'email';

    public static promolink_domain_key = 'ds24_domain';

    public static testpay_key = 'testpay';
    public static testpay_team_key = 'testpay_team';

    public static initialize() {
        this.promo_parameter = PROMO_PARAMETER_INIT_VALUE;
    }
}

export const SESSION_STORAGE_KEY = 'ds24s.v1';
export const PERMANENT_STORAGE_KEY = 'ds24p.v1'
export const COOKIE_STORAGE_KEY = 'ds24c.v1';

export const PARAMETER_TO_APPEND: (keyof PromoParameter)[] = [
  Configuration.vendor_key,
  Configuration.affiliate_key,
  Configuration.campaignkey_key,
  ...Configuration.link_id_keys,
  ...Configuration.utm_keys,
  ...Configuration.click_amt_keys,
  Configuration.email_key,
  Configuration.testpay_key,
  Configuration.testpay_team_key,
] as unknown as (keyof PromoParameter)[];

export const PROMO_PARAMETER_LIST = [
    ...PARAMETER_TO_APPEND,
    Configuration.promolink_domain_key,
];

// Setup deprecated configuration
export function setupDeprecatedGlobalVar(globalKey: string, configKey: string[], encode?: (v: any) => any, decode?: (obj: any, v: any) => any) {
    if ((window as any)[globalKey] !== undefined) {
        return;
    }
    Object.defineProperty(window, globalKey, {
        get: () => {
            console.warn('[Digistore24] Deprecated configuration: Do not read value from window.' + globalKey + ' use api functions!');
            const rawValue = configKey.reduce((value: any, key) => {
                return value[key];
            }, Configuration);
            return encode ? encode(rawValue) : rawValue;
        },
        set: (v)  => {
            console.warn('[Digistore24] Deprecated configuration: Do not set window.' + globalKey + ' = <value> use api functions!');
            const obj = configKey.slice(0, -1).reduce((value: any, key) => {
                return value[key];
            }, Configuration);
            obj[configKey[configKey.length - 1]] = decode ? decode(obj[configKey[configKey.length - 1]], v) : v;
        }
    });    
}


setupDeprecatedGlobalVar('DIGISTORE_VENDOR_VAL', ['promo_parameter', 'ds24tr']);
setupDeprecatedGlobalVar('DIGISTORE_AFFILIATE_VAL', ['promo_parameter', 'aff']);
setupDeprecatedGlobalVar('DIGISTORE_CAMPAIGNKEY_VAL', ['promo_parameter', 'cam']);
setupDeprecatedGlobalVar('DIGISTORE_LINK_ID_VAL', ['promo_parameter'], (v) => {
    return Configuration.link_id_keys.map(x => v[x]).join(Configuration.link_id_sep);
}, (obj, v) => {
    const values = v.split(Configuration.link_id_sep);
    
    Configuration.link_id_keys.forEach((key, index) => {
        obj[key] = values[index] ?? '';
    });
    return obj;
});
setupDeprecatedGlobalVar('DIGISTORE_UTM_VAL', ['promo_parameter'], (v) => {
    return Configuration.utm_keys.map(x => v[x]).join(Configuration.link_id_sep);
}, (obj, v) => {
    const values = v.split(Configuration.link_id_sep);
    
    Configuration.utm_keys.forEach((key, index) => {
        obj[key] = values[index] ?? '';
    });
    return obj;
});
setupDeprecatedGlobalVar('DIGISTORE_CLICK_AMT_VAL', ['promo_parameter'], (v) => {
    return Configuration.click_amt_keys.map(x => v[x]).join(Configuration.link_id_sep);
}, (obj, v) => {
    const values = v.split(Configuration.link_id_sep);
    Configuration.click_amt_keys.forEach((key, index) => {
        obj[key] = values[index] ?? '';
    });
    return obj;
});
setupDeprecatedGlobalVar('DIGISTORE_EMAIL_VAL', ['promo_parameter', 'email']);


setupDeprecatedGlobalVar('DIGISTORE_AFFILIATE_KEY', ['affiliate_key']);
setupDeprecatedGlobalVar('DIGISTORE_CAMPAIGNKEY_KEY', ['campaignkey_key']);
setupDeprecatedGlobalVar('DIGISTORE_VENDORKEY', ['vendor_key']);
setupDeprecatedGlobalVar('DIGISTORE_LINK_ID_SEP', ['link_id_sep']);
setupDeprecatedGlobalVar('DIGISTORE_LINK_ID_KEY', ['link_id_keys']);
setupDeprecatedGlobalVar('DIGISTORE_UTM_KEY', ['utm_keys']);
setupDeprecatedGlobalVar('DIGISTORE_CLICK_AMT_KEY', ['click_amt_keys']);
setupDeprecatedGlobalVar('DIGISTORE_EMAIL_KEY', ['email_key']);
