import { DigistoreAppendAffiliateToBuyUrlsFunction } from "../public-interfaces";
import { ds24_debug_function } from "./common/ds24_debug";
import { digistorePromoPixel } from "./digistorePromoPixel";

/**
 * It calls {@link digistorePromocode} and adds all tracking parameter to Digistore24 order form links.
 * @deprecated It's already done with {@link digistorePromocode} call by default.
 * @param product_id Digistore24 product id.
 * @param affiliate_key Affiliate tracking parameter name.
 * @param campaignkey_key Campaign key tracking parameter name.
 */
export const digistoreAppendAffiliateToBuyUrls: DigistoreAppendAffiliateToBuyUrlsFunction = 
    function( product_id: number, affiliate_key: string, 
    campaignkey_key: string ): void
{
    // eslint-disable-next-line prefer-rest-params
    ds24_debug_function('digistoreAppendAffiliateToBuyUrls', arguments);
    console.warn("digistoreAppendAffiliateToBuyUrls() is deprecated. Please use digistorePromoPixel(), it's now a default behavior to append the affiliate to buy urls.");
    digistorePromoPixel( product_id, affiliate_key, campaignkey_key );
}
