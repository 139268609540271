export interface InterfacePostMessageRedirect {
  ds24action: 'redirect';
  iframe_index: number;
  payload: {
    redirect_id: string;
    delay_ms: number;
  };
}

export const _DS24_EVENT_ORIGIN = __PRODUCTION_URL__;

function handleRedirect(data: InterfacePostMessageRedirect) {
  if (data?.ds24action === 'redirect') {
    const url =
      _DS24_EVENT_ORIGIN + '/extern/ajax/redirect/' + data.payload.redirect_id;
    window.location.href = url;
  }
}

/**
 * Quick coding... https://digistore.atlassian.net/browse/DS-11983
 * @param event
 * @constructor
 */
export const PostMessageReceiver = (event: MessageEvent) => {
  if (event.data) {
    try {
      const parsed: InterfacePostMessageRedirect = JSON.parse(event.data);
      handleRedirect(parsed);
    } catch (e) {
      const params = window.location.search;
      if (params.includes('debug')) {
        console.log(
          'Fail to parse received postmessage: ' + JSON.stringify(event.data)
        );
      }
    }
  }
};
