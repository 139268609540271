import { flushQueue } from './flush-queue';
import { documentReady } from './functions/browser/documentReady';
import { PostMessageReceiver } from './functions/common/ds24_post_messages';

export async function tryToLoadOrderFormElement() {
    const widget = await import(/* webpackMode: "lazy", webpackChunkName: "widget" */ '../widget');
    for(const publicFunctionName in widget) {
        (window as any)[publicFunctionName] = (widget as any)[publicFunctionName];
    }
}

export function initialize() {
    /**
     * Subscribe on message events
     */

    window.addEventListener('message', PostMessageReceiver);

    flushQueue();

    documentReady(() => {
        if (document.querySelector('my-order-form')) {
            tryToLoadOrderFormElement();
        } else if (window.MutationObserver) {
            const mutuationObserver = new MutationObserver((list, ob) => {
                for(const record of list) {
                    for(let i = 0; i < record.addedNodes.length; i++) {
                        if (record.addedNodes[i].nodeName === 'my-order-form'.toUpperCase()) {
                            tryToLoadOrderFormElement();
                            ob.disconnect();
                            return;
                        }
                        
                    }
                }
            });
            mutuationObserver.observe(document.body, {subtree: true, childList: true});
        }
    });
    
}
