
let ds24cart_unique_counter: number;
export function ds24_unique_id(id: string = '') {

    if (typeof ds24cart_unique_counter == 'undefined') {
        ds24cart_unique_counter = 0;
    }

    return 'id' + (new Date().getTime()) + '_' + Math.round(Math.random() * 10000) + '_' + ds24cart_unique_counter++;
}
