
function ds24_debug_active() {
    return window.location.search?.match(/debug=1/);
}

export function ds24_debug_function(name: string, args: IArguments|any[]) {
    if (!ds24_debug_active()) {
        return;
    }
    console.log('DEBUG:', name + '(', Array.from(args).filter(param => typeof(param) !== 'undefined'), ')');
}
