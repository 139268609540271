import { ds24_checkout_url } from './ds24_checkout_url';
import { ds24_debug_function } from './ds24_debug';

let dynamicAttr: {
  [key: string]: number | string;
} = {};
let sessionTmpID: string;
let timeout: any;
// const domain = 'http://www.digistore-checkout.local'; // for local testing
const domain = ds24_checkout_url();
export const UPSELL_BOX_DELAY = 250;

/**
 * diese werte sind mehrfach im Projekt vorhanden!
 * Bitte bei änderungen die Keys im gesammten Projekt suchen
 */
export const NamingPrefix = 'digistore-upsell-button';
export const NamingPrefixUnderCore = 'digistore_upsell_button';

const searchForRadios = () => {
  return document.querySelectorAll<HTMLInputElement>(
    `input[name=${NamingPrefix}-radio]`
  );
};

const selectRadiosWithEqualValue = (type: string, value: string | number) => {
  dynamicAttr[type] = value;

  /**
   * select radios with equal value
   */
  searchForRadios().forEach((item) => {
    if (item.value === `${type}-${value}`) {
      item.checked = true;
    }
  });
};

export const digistoreSetUpsellChoice = (
  type: string,
  value: string | number
) => {
  // eslint-disable-next-line prefer-rest-params
  ds24_debug_function('digistoreSetUpsellChoice', [type, value]);
  dynamicAttr[type] = value;

  selectRadiosWithEqualValue(type, value);

  timeout = setTimeout(
    () => initializeDs24UpsellButtons(sessionTmpID, dynamicAttr),
    UPSELL_BOX_DELAY
  );
};

/**
 * Helper to get radio value
 * @param radio
 */
const getRadioValue = (radio: HTMLInputElement) => {
  const valueSplit = radio.value.split('-');
  return {
    key: valueSplit[0],
    value: valueSplit[1],
  };
};

/**
 * set Radio Change listener to trigger digistoreSetUpsellChoice
 */
export const _watchForDS24Radios = () => {
  const allRadios = searchForRadios();
  allRadios.forEach((element) => {
    element.onchange = () => {
      if (element.checked) {
        const valueSplit = getRadioValue(element);
        digistoreSetUpsellChoice(valueSplit.key, valueSplit.value);
      }
    };
  });
  console.log('radios', allRadios);
};

/**
 * preset first option radio for Upsell
 * use first checked radio or select first radio
 */
export const useFirstRadio = () => {
  const allRadios = searchForRadios();

  const findCheckedRadio = Array.from(allRadios).find(
    (element) => element.checked
  );

  if (findCheckedRadio) {
    const valueSplit = getRadioValue(findCheckedRadio);
    digistoreSetUpsellChoice(valueSplit.key, valueSplit.value);
    console.log('preset selected radio', findCheckedRadio);
  } else if (allRadios.length) {
    const valueSplit = getRadioValue(allRadios[0]);
    digistoreSetUpsellChoice(valueSplit.key, valueSplit.value);
    console.log('preset first radio', allRadios[0]);
  }
};

interface InterfacePostMessagePayFrameHeight {
  ds24action: 'setPayFrameHeight';
  payload: {
    height: number;
    width: number;
  };
}

export const iFrameResizeListener = (
  event: MessageEvent,
  allButtonIframes: HTMLIFrameElement[]
) => {
  // because the data is an json string, we can check the string content
  const rightCheck = ('' + event.data).includes('setPayFrameHeight');

  if (event.data && rightCheck) {
    try {
      const resizeData: InterfacePostMessagePayFrameHeight = JSON.parse(
        event.data
      );

      /**
       * check data
       */
      console.log('resizeData', resizeData);
      if (
        resizeData?.ds24action === 'setPayFrameHeight' &&
        resizeData.payload
      ) {
        allButtonIframes.forEach((iframe) => {
          if (resizeData.payload?.width) {
            iframe.width = `${resizeData.payload.width}px`;
          }
          if (resizeData.payload?.height) {
            iframe.height = `${resizeData.payload.height}px`;
          }
        });
      }
    } catch (e) {
      console.log(
        'Fail to parse received message from upsell button postmessage: ' +
          JSON.stringify(event.data)
      );
    }
  }
};

export const getUpsellIframe = (
  src: string,
  width: string = '100%',
  height: string = '400px'
) => {
  console.log('getUpsellIframe', width, height);

  return `<iframe
           data-e2e="upsell-button-iframe"
          class="upsell-button-iframe"
          src="${src}"
          loading="lazy"
          style="overflow: hidden;border: none; background: transparent;"
          height="${height}"
          width="${width}"
          sandbox="allow-top-navigation allow-modals allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          />`;
};

export const initializeDs24UpsellButtons = (
  sessionId: string,
  dynamicParams = dynamicAttr
) => {
  if (timeout) {
    clearTimeout(timeout);
  }

  /**
   * Resize iframes on postmessage
   * @param $event
   */
  const callBack = ($event: MessageEvent<any>) => {
    /**
     * change iframes
     */
    const allButtonIframes = document.querySelectorAll<HTMLIFrameElement>(
      '.upsell-button-iframe'
    );
    iFrameResizeListener($event, Array.from(allButtonIframes));
  };
  window.removeEventListener('message', callBack);
  window.addEventListener('message', callBack);

  /**
   * save session for digistoreSetUpsellChoice
   */
  sessionTmpID = sessionId;

  _watchForDS24Radios();

  const upsellButtons = document.querySelectorAll<HTMLElement>(
    `${NamingPrefix}`
  );

  upsellButtons.forEach(handle);
  console.log('upsell-buttons', upsellButtons);

  function handle(element: HTMLElement) {
    let src = `${domain}/upsell_buy_button/${sessionId}`;

    let attributes = Array.from(element.attributes).map(
      (attr) => `${attr.nodeName}=${attr.nodeValue}`
    );

    let dynamicAttributes = Object.keys(dynamicParams).map((key) => {
      return `${key}=${dynamicParams[key]}`;
    });

    const allAttributes = [...attributes, ...dynamicAttributes];

    if (allAttributes.length) {
      src += `?` + allAttributes.join('&');
    }

    const iframeElements = element.getElementsByTagName('iframe');
    if (iframeElements.length) {
      Array.from(iframeElements).forEach((iframe) => {
        iframe.src = src;
      });
    } else {
      const iframeHTML = getUpsellIframe(src);
      element.innerHTML = iframeHTML;
    }
  }
};
