import { ds24_debug_function } from './ds24_debug';
import { DS24Modal } from './ds24_modal';
import { getUpsellIframe, iFrameResizeListener } from './ds24_upsell_box';

export const ActionDelay = 0;

export interface InterfaceUpsellInfo {
  action: 'redirect' | 'show_iframe' | 'show_message';
  url?: string;
  headline?: string;
  iframe_url?: string;
  iframe_height?: number;
  iframe_width?: number;
  message?: string;
}

/**
 * Shows the digistore upsell modal. 
 * @param upsellURL url of the upsell inculding session id.
 * @param shadowDom use shadow dom, default behavior is true
 * @returns 
 */
export function ds24UpsellModal(upsellURL: string, shadowDom = true) {
  // eslint-disable-next-line prefer-rest-params
  ds24_debug_function('ds24UpsellModal', arguments);
  const overlay = new DS24Modal('upsell_modal');
  overlay.useShadowDom = shadowDom;

  const request: RequestInit = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  };

  overlay.loading();

  return fetch(upsellURL, request)
    .then((response) => response.json())
    .then((response: InterfaceUpsellInfo) => {
      setTimeout(() => {
        if (response.action === 'show_message' && response.message) {
          overlay.modal(
            '',
            `<div style="padding: 0 15px">${response.message}</div>`
          );
          return;
        }

        if (response.action === 'redirect' && response.url) {
          window.location.href = response.url;
          return;
        }

        if (response.action === 'show_iframe' && response.iframe_url) {
          const containerID = 'modalIframe';

          /**
           * Subscribe on message event to change iframe size
           */
          const callBack = ($event: MessageEvent<any>) => {
            const iframeSearchContainer = overlay
              .getContainer()
              .querySelector(`#${containerID}`);
            const modalIframe =
              iframeSearchContainer?.getElementsByTagName('iframe');
            if (modalIframe?.length) {
              iFrameResizeListener($event, Array.from(modalIframe));
            }
          };
          window.removeEventListener('message', callBack);
          window.addEventListener('message', callBack);

          const iframe = getUpsellIframe(
            response.iframe_url,
            response.iframe_width + 'px',
            response.iframe_height + 'px'
          );

          overlay.modal(
            response.headline || '',
            `<div id="${containerID}" style="margin-bottom: -9px;">${iframe}</div>`,
            '',
            true,
          );

          return;
        }
      }, ActionDelay);
    })
    .catch((e) => {
      overlay.openOverlay(`${e}`);
      setTimeout(() => {
        window.location.href = upsellURL;
      }, ActionDelay);
    });
};
