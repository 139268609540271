

export function documentReady(callback: () => void) {
    if (document.readyState === 'complete') {
        callback();
        return;
    }

    const callbackWrapperWithCleanup = () => {
        callback();
        window.removeEventListener('load', callbackWrapperWithCleanup, false);
        window.removeEventListener('DOMContentLoaded', callbackWrapperWithCleanup, false);
    };
    
    window.addEventListener('load', callbackWrapperWithCleanup, false);
    document.addEventListener('DOMContentLoaded', callbackWrapperWithCleanup, false);
}