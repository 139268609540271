import { digistoreSanitizeLocation } from '../digistoreSanitizeLocation';
import { DEFAULT_PROMOPIXEL_DOMAINS, digistoreAddCustomDomain, ds24_root_url } from '../common/ds24_root_url';
import { ds24_sha256 } from '../common/ds24_sha256';
import { request } from '../browser/request';
import { LinkReplacerEngine } from '../browser/LinkReplacer';
import { AffiliateTrackingLinkAppender } from './affiliate-tracking-link-appender';
import { Configuration, COOKIE_STORAGE_KEY, PARAMETER_TO_APPEND, PERMANENT_STORAGE_KEY, PROMO_PARAMETER_LIST, SESSION_STORAGE_KEY } from '../../configuration';
import { BuyLinkDomainReplacer } from './buy-link-domain-replacer';
import { urlSearchParamsDecode } from '../browser/url-search-params-decode';
import { PromoParameter } from './model';
import { ds24_get_cookie, ds24_set_cookie } from '../common/ds24_cookie';

export interface PromoPixelSessionData {
  loaded?: boolean;
  orderform_domain?: string;
  can_store?: boolean;
  parameter?: any;
}

const linkReplacer = new LinkReplacerEngine();

let is_tracking_pixel_included: {
  [domain: string]: true | undefined;
} = {};

export async function ds24_promopixel_loader() {

  const encodedParameter = await ds24_promopixel();

  if (Configuration.adjust_domain) {
    linkReplacer.add(new BuyLinkDomainReplacer(Configuration.orderform_domain));
  }

  linkReplacer.add(new AffiliateTrackingLinkAppender(
    Configuration.adjust_all_urls,
    PARAMETER_TO_APPEND,
    Configuration.promo_parameter,
  ));

  linkReplacer.run();

  const scriptMarker = document.createElement('div');
  scriptMarker.setAttribute('id', 'digistore24_js_included');
  scriptMarker.style.display = 'none';
  document.body.appendChild(scriptMarker);
}

interface ResponsePromolinkEmail {
  password: string;
}

interface ResponsePromoPixel {
  affiliate: string;
  can_store: boolean;
  campaignkey: string;
  orderform_domain: string;
}

/**
 * Wird im Unit-Test benötigt!
 */
export function _TEST_reset_tracking_pixel_included() {
  is_tracking_pixel_included = {};
}

export function ds24_promopixel_read_session_store(): PromoPixelSessionData {
  let sessionData;
  try {
    const sessionDataString = window.sessionStorage?.getItem(SESSION_STORAGE_KEY);
    sessionData = JSON.parse(sessionDataString as string);
    if (typeof(sessionData) !== 'object' || sessionData === null) {
      sessionData = {};
    }
  } catch(e) {
    sessionData = {};
  }
  return sessionData;
}



let _ds24_promopixel_read_parameter_once = false;
export function _TEST_reset_ds24_promopixel_read_parameter_once() {
  _ds24_promopixel_read_parameter_once = false;
}

export function ds24_promopixel_read_parameter(): PromoParameter {

  if (_ds24_promopixel_read_parameter_once) {
    return Configuration.promo_parameter;
  }

  // Versuche daten aus Browser Sitzung zu laden
  const sessionData: PromoPixelSessionData = ds24_promopixel_read_session_store();
  let parameter: PromoParameter = Configuration.promo_parameter;
  if (sessionData.parameter !== null && sessionData.parameter !== undefined) {
    const sessionParameterList = Object.keys(sessionData.parameter as any);
    if (sessionParameterList.length > 0) {
      sessionParameterList.filter(key => PROMO_PARAMETER_LIST.indexOf(key as keyof PromoParameter) >= 0).forEach(key => {
        const value = sessionData.parameter[key];
        parameter[key as keyof PromoParameter] = !value || value === 'null' ? '' : value;
      });
    }
  }

  // Es gibt keine Browser-Sitzungsdaten, dann versuche aus dem local Storage parameter zu laden
  if (!sessionData.parameter || Object.keys(sessionData.parameter as any).length === 0) {
    try {
      const dataString = window.localStorage?.getItem(PERMANENT_STORAGE_KEY);
      const data = JSON.parse(dataString as string);
      
      if (typeof(data?.parameter) === 'object' && data.parameter !== null) {
        Object.keys(parameter).filter(key => PROMO_PARAMETER_LIST.indexOf(key as keyof PromoParameter) >= 0).forEach(key => {
          const value = data.parameter[key];
          parameter[key as keyof PromoParameter] = !value || value === 'null' ? '' : value;
        });
      }
    // eslint-disable-next-line no-empty
    } catch(e) {
    }
  }

  try {
    const data = JSON.parse(ds24_get_cookie(COOKIE_STORAGE_KEY));
    if (typeof(data?.parameter) === 'object' && data.parameter !== null) {
      Object.keys(parameter).filter(key => PROMO_PARAMETER_LIST.indexOf(key as keyof PromoParameter) >= 0).forEach(key => {
        const value = data.parameter[key];
        parameter[key as keyof PromoParameter] = !value || value === 'null' ? '' : value;
      });
    }
  // eslint-disable-next-line no-empty
  } catch(e) {
  }

  Configuration.promo_parameter = parameter;
  
  // Gibt es in der URL Parameter, dann diese verwenden
  let urlParameter: {[key: string]: string} = {};

  if (location.hash) {
    urlParameter = urlSearchParamsDecode(location.hash);
  }

  if (location.search) {
    urlParameter = {
      ...urlParameter,
      ...urlSearchParamsDecode(location.search)
    };
  }

  // nur wenn es query string parameter gibt
  if (Object.keys(urlParameter).length > 0) {
    // Alternativen E-Mail Parameter-Key aus Wordpress Migration
    if (urlParameter['e'] && !urlParameter[Configuration.email_key]) {
      urlParameter[Configuration.email_key] = urlParameter['e'];
    }

    // alle nicht tracking parameter entfernen
    Object.keys(urlParameter).filter(key => PROMO_PARAMETER_LIST.indexOf(key as keyof PromoParameter) < 0).forEach(key => {
      delete urlParameter[key];
    });

    Configuration.promo_parameter = parameter = {
      ...parameter,
      ...urlParameter,
    } as any;
  }

  _ds24_promopixel_read_parameter_once = true;

  return Configuration.promo_parameter;
}

export async function ds24_promopixel() {
  if (typeof window.DIGISTORE_PRODUCT_ID == 'undefined') {
    return;
  }

  const parameter = ds24_promopixel_read_parameter();

  // parameter werden kodiert, für den Aufruf des Bestellformualar und den Promopixel
  const encodedPromoLinkParameter = {
    ...parameter,
  } as any;
  
  const paramsToEncode: {[key: string]: string[]} = {
    link_id: Configuration.link_id_keys,
    utm_data: Configuration.utm_keys,
    click_amt: Configuration.click_amt_keys,
  };
  
  Object.keys(paramsToEncode).forEach(key => {
    encodedPromoLinkParameter[key] = paramsToEncode[key].reduce((str, param) => {
      str += (encodedPromoLinkParameter[param] ?? '') + Configuration.link_id_sep;
      delete encodedPromoLinkParameter[param];
      return str;
    }, '');
    if (encodedPromoLinkParameter[key].match(new RegExp("^" + Configuration.link_id_sep + "+$"))) {
      encodedPromoLinkParameter[key] = '';
    }
  });
  
  const vendor = encodedPromoLinkParameter[Configuration.vendor_key] ?? '';
  const affiliate = encodedPromoLinkParameter[Configuration.affiliate_key];
  const campaignkey = encodedPromoLinkParameter[Configuration.campaignkey_key];
  const email = encodedPromoLinkParameter[Configuration.email_key];

  const link_id = encodedPromoLinkParameter['link_id'];
  const utm_data = encodedPromoLinkParameter['utm_data'];
  const click_amt = encodedPromoLinkParameter['click_amt'];

  const promolinkDomain = encodedPromoLinkParameter[Configuration.promolink_domain_key];

  const promolinkQuery = 'group_sids=' +
    link_id +
    '&ds24tr=' +
    vendor +
    '&ds24utm=' +
    utm_data +
    '&ds24cmt=' +
    click_amt + 
    '&ds24domain=' + encodeURIComponent(promolinkDomain ?? document.referrer) +
    '&ds24ref=' + encodeURIComponent(document.referrer);
    
  // Nur ein einziges mal pro Sitzung
  const sessionData: PromoPixelSessionData = ds24_promopixel_read_session_store();
  if (!sessionData.loaded) {    
    sessionData.loaded = true;

    // Promopixel für die Hauptseite separat laden mit get_domain=1 Xhr Request
    is_tracking_pixel_included[ds24_root_url()] = true;

    // Für alle Domains Promopixel einbinden.
    DEFAULT_PROMOPIXEL_DOMAINS.forEach((domain) => {
      ds24_promopixel_embed(domain, affiliate, campaignkey, promolinkQuery, true);
    });
    
    // E-Mail Tracking nur für die Checkout-URL
    ds24_promomail_embed(Configuration.checkout_url, email, affiliate, campaignkey, promolinkQuery);
    
    const promoPixelData = await request<ResponsePromoPixel>({
      url: ds24_promopixel_build_track_url(ds24_root_url(), affiliate, campaignkey, promolinkQuery, 'json') + '&get_domain=1',
      dataType: 'jsonp'
    });
    
    // Nur, mit can_store dürfen im localStorage laden.
    sessionData.can_store = promoPixelData?.can_store;

    if (promoPixelData.orderform_domain) {
      sessionData.orderform_domain = promoPixelData.orderform_domain;
      ds24_promopixel_embed(window.location.protocol + '//' + promoPixelData.orderform_domain, affiliate, campaignkey, promolinkQuery, true);
    }
  }

  sessionData.parameter = Configuration.promo_parameter;

  if (sessionData.orderform_domain) {
    Configuration.orderform_domain = sessionData.orderform_domain;
    if (DEFAULT_PROMOPIXEL_DOMAINS.indexOf(window.location.protocol + '//' + sessionData.orderform_domain) < 0) {
      digistoreAddCustomDomain(window.location.protocol + '//' + sessionData.orderform_domain);
    }
  }

  Configuration.promo_code_loaded = true;

  if (sessionData.can_store) {
    const storeData = JSON.stringify({parameter: parameter });
    try {
      window.localStorage.setItem(PERMANENT_STORAGE_KEY, storeData);
      // eslint-disable-next-line no-empty
    } catch(e) {
    }
    ds24_set_cookie(COOKIE_STORAGE_KEY, storeData, 7);
  }

  try {
    window.sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(sessionData));
    // eslint-disable-next-line no-empty
  } catch(e) {
  }

  digistoreSanitizeLocation([
    Configuration.vendor_key,
    Configuration.affiliate_key,
    Configuration.campaignkey_key,
    Configuration.link_id_keys,
    Configuration.click_amt_keys,
    Configuration.promolink_domain_key,
  ]);

  return encodedPromoLinkParameter;
}

/**
 * PromoPixel einbinden
 * @param baseUrl 
 * @param affiliate 
 * @param campaignkey 
 * @param queryParameter 
 * @param skipCount 
 */
export function ds24_promopixel_embed(baseUrl: string, affiliate: string|null, campaignkey: string|null, queryParameter: string, skipCount = true) {

  const must_include_pixel =
  typeof is_tracking_pixel_included[baseUrl] == 'undefined';
  
  if (must_include_pixel) {
    is_tracking_pixel_included[baseUrl] = true;

    const url = ds24_promopixel_build_track_url(baseUrl, affiliate, campaignkey, queryParameter + (skipCount ? '&skip_count=1' : ''));

    const promopixel = document.createElement('img');
    promopixel.id = 'digistore_promopixel';
    promopixel.setAttribute(
      'style',
      'width: 1px !important; height: 1px !important; padding: 0 !important; margin: 0 !important; ' +
        'display: block !important; position: absolute !important; bottom: 0 !important; right: 0 !important; z-index: -1 !important; ' +
        'border: none !important; background: transparent !important;'
    );
    promopixel.setAttribute('src', url);
    document.body.appendChild(promopixel);
  }
}

/**
 * Einbindung des E-Mail Promopixel
 * @param baseUrl 
 * @param email 
 * @param affiliate 
 * @param campaignkey 
 * @param queryParameter 
 */
export function ds24_promomail_embed(baseUrl: string, email: string, affiliate: string|null, campaignkey: string|null, queryParameter: string) {
  if (email && affiliate) {
    const url =
      baseUrl + '/buy/promolink/get_onetime_pass/' + ds24_sha256(email);

    request<ResponsePromolinkEmail>({
      url,
      dataType: 'json',
      success: (data) => {
        const url =
          baseUrl +
          '/buy/promolink/email/' +
          affiliate +
          '/' +
          (campaignkey
            ? campaignkey
            : 'CAMPAIGNKEY') +
          '/' +
          window.DIGISTORE_PRODUCT_ID +
          '/' +
          ds24_sha256(email) +
          '/' +
          data.password +
          '.png?' + queryParameter;

        const promopixelEmail = document.createElement('img');
        promopixelEmail.id = 'digistore_promopixel_email';
        promopixelEmail.setAttribute(
          'style',
          'width: 1px !important; height: 1px !important; padding: 0 !important; ' +
            'margin: 0 !important; display: block !important; position: absolute !important; bottom: 0 !important; ' +
            'right: 0 !important; z-index: -1 !important; border: none !important; background: transparent !important;'
        );
        promopixelEmail.setAttribute('src', url);
        document.body.appendChild(promopixelEmail);
      },
    });
  }
}

export function ds24_promopixel_build_track_url(baseUrl: string, affiliate: string|null, campaignkey: string|null, queryParameter: string, type: 'image' | 'json' = 'image') {
  return baseUrl +
    '/track/' +
    (affiliate ? affiliate : 'AFFILIATE') +
    '/' +
    window.DIGISTORE_PRODUCT_ID +
    (campaignkey ? '/' + campaignkey : '') +
    (type === 'image' ? 
    '.png' : '.json') + '?' + queryParameter;
}

