export function ds24_set_cookie(
  cookie_name: string,
  cookie_value: string,
  expire_days: number
) {
  const d = new Date();

  d.setTime(d.getTime() + expire_days * (24 * 60 * 60 * 1000));

  const expires = 'expires=' + d.toUTCString();

  document.cookie =
    cookie_name + '=' + cookie_value + ';' + expires + ';path=/';

  if (typeof window.ds24_cookies === 'undefined') {
    window.ds24_cookies = {};
  }

  window.ds24_cookies[cookie_name] = cookie_value;
}

export function ds24_get_cookie(cookie_name: string) {
  if (typeof window.ds24_cookies === 'undefined') {
    window.ds24_cookies = {};
  }

  if (typeof window.ds24_cookies[cookie_name] != 'undefined') {
    return window.ds24_cookies[cookie_name];
  }

  const name = cookie_name + '=';

  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}
