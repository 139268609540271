export const DEFAULT_PROMOPIXEL_DOMAINS = [
  __PRODUCTION_URL__,
  __CHECKOUT_URL__,
];

export function digistoreAddCustomDomain(customDomain: string) {
  if (!customDomain.startsWith('http')) {
    customDomain = 'https://' + customDomain;
  }
  DEFAULT_PROMOPIXEL_DOMAINS.push(customDomain.replace(/\/+$/, ''));
}

export function ds24_root_url() {
  return typeof window.DIGISTORE_ROOT_URL != 'undefined'
    ? window.DIGISTORE_ROOT_URL.replace(/\/$/, '')
    : __PRODUCTION_URL__;
}

export function ds24_set_root_url(url: string) {
  console.log('Digistore24 - using test root url: ', url);
  window.DIGISTORE_ROOT_URL = url;
}
